import React from 'react' // eslint-disable-line no-unused-vars
import { css } from '@emotion/react'
import { FrontPageQuery, TagPageQuery } from 'generated/types/gatsby' // eslint-disable-line no-unused-vars
import { Link } from 'gatsby' // eslint-disable-line no-unused-vars
const MDXRenderer = require('gatsby-plugin-mdx/mdx-renderer') // eslint-disable-line no-unused-vars

interface ContentListProps {
	readonly edges: FrontPageQuery['allMdx']['edges'] | TagPageQuery['allMdx']['edges']
}

const list = css`
	line-height: 1.8;
	list-style: none;
	padding: 0;
	margin: 1rem 0 2rem;
`

const item = css``

export const NewsList = ({ edges }: ContentListProps) => (
	<section css={list}>
		{edges.map(({ node }) => {
			const { path, title } = node.frontmatter
			// const x = Object.keys(node).join(', ')
			return (
				<section css={item} key={path.replace(/__t/, 'news')}>
					<h2>
						<Link to={path.replace(/__t/, 'news')}>{title}</Link>
					</h2>
					<span>({node.frontmatter.date})</span>
					<MDXRenderer>{node.body}</MDXRenderer>
				</section>
			)
		})}
	</section>
)
