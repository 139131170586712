/* eslint-disable no-unused-vars */
import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { FrontPageQuery, FrontPageQueryVariables } from 'generated/types/gatsby'
import { NewsList } from '../components/NewsList'
import { Pager } from '../components/Pager'
import { Layout } from '../components/Layout'
import { ArchivePageContext } from '../context'
/* eslint-enable no-unused-vars */

interface FrontPageProps {
	readonly data: FrontPageQuery

	readonly pageContext: ArchivePageContext & FrontPageQueryVariables
}

const FrontTemplate = ({ data, pageContext }: FrontPageProps) => {
	const { page, prefix, pageTotal } = pageContext

	return (
		<Layout>
			<Helmet
				meta={[
					{ name: 'description', content: 'Sample' },
					{ name: 'keywords', content: 'sample, something' },
				]}
			/>
			<NewsList edges={data.allMdx.edges} />
			<Pager page={page} prefix={prefix} total={pageTotal} />
		</Layout>
	)
}

export default FrontTemplate

export const query = graphql`
	query FrontPage($tag: String!, $skip: Int!, $limit: Int!) {
		allMdx(
			filter: { frontmatter: { draft: { ne: true }, tags: { in: [$tag] } } }
			sort: { order: DESC, fields: [frontmatter___date] }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					frontmatter {
						date(formatString: "MMMM D, YYYY")
						path
						title
					}
					body
				}
			}
		}
	}
`
